import React from 'react';
import i18next from 'i18next';
import {NavLink} from 'react-router-dom';
import DefaultLayout from './../layouts/Default';
import {
    clearFilters
} from '../store/filter/actions';


export default function ImprintPage(props) {

    return (
        <DefaultLayout
            content={
                <div className="page__content container mt-4">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <NavLink
                                className="link"
                                to={`/`}
                                onClick={() => clearFilters()}>
                                {i18next.t('Home')}
                            </NavLink>
                            <h1 className="mb-4">Impressum</h1>
                            <p>Unfallkasse Nordrhein-Westfalen<br></br>Straße 18 40227 Düsseldorf</p>

                            <p>Telefon: 0211 9024 0<br></br>E-Mail: info@unfallkasse-nrw.de</p>

                            <p>Die Unfallkasse NRW ist eine Körper­schaft des öffentlichen Rechts. Die
                                Unfallkasse NRW ist ein Unfallver­sicherungsträger der öffent­lichen
                                Hand für die gesetzliche Unfallver­sicherung in NRW.</p>

                            <p>Inhaltlich Verant­wortlicher gemäß § 55 Abs. 2 Rundfunkstaats­vertrag (RStV):
                                <br></br>Gabriele Pappai, Geschäftsführerin
                                <br></br>(Anschrift wie oben)</p>

                            <h2 className="my-4">Redaktion</h2>
                            <p>Alexandra Daldrup</p>

                            <h2 className="my-4">Konzeptionelle, grafische und technische Realisierung</h2>
                            <p>rend Medien Service GmbH
                                <br></br>Bahnhofstraße 1
                                <br></br>41747 Viersen
                                <br></br><a href="https://www.rend.de/" target="_blank"
                                            rel="noopener noreferrer">www.rend.de</a></p>

                            <h2 className="my-4">Copyright</h2>
                            <p>Der Inhalt dieser Webseiten ist urheberrechtlich geschützt. Alle Abbildungen und Fotos
                                auf den Webseiten der Unfallkasse
                                Nordrhein-Westfalen sind Eigentum der Unfallkasse Nordrhein-Westfalen und dürfen ohne
                                ihre Zustimmung insbesondere nicht übernommen,
                                vervielfältigt, verbreitet oder in irgendeiner Form auf Datenträgern oder
                                anderen Medien gespeichert oder genutzt werden. Die Unfallkasse
                                Nordrhein-Westfalen gestattet die Übernahme von Texten in Datenbestände, die
                                ausschließlich für den privaten Gebrauch eines Nutzers bestimmt sind. Die
                                Übernahme und Nutzung der Daten zu anderen Zwecken bedarf der
                                schriftlichen Zustimmung der Unfallkasse Nordrhein-Westfalen.</p>

                            <h2 className="my-4">Disclaimer</h2>
                            <p>Die Unfallkasse NRW ist als Diensteanbieter nach § 7 Abs. 1 TMG für die "eigenen
                                Inhalte", die sie zur Nutzung bereithält nach den allgemeinen
                                Gesetzen verantwortlich. Von diesen eigenen Inhalten sind
                                Querverweise ("Links") von anderen Anbietern bereitgehaltenen
                                Inhalte zu unterscheiden. Durch den Querverweis hält die Unfallkasse
                                NRW insofern "fremde Inhalte" zur Nutzung bereit:</p>
                            <p>Bei "Links" handelt es sich stets um "lebende" (dynamische)
                                Verweisungen. Die Unfallkasse
                                NRW hat bei der erstmaligen Verknüpfung zwar den fremden Inhalt daraufhin
                                überprüft, ob durch ihn eine mögliche zivilrechtliche oder
                                strafrechtliche Verantwortlichkeit ausgelöst wird. Die Unfallkasse
                                NRW überprüft aber die Inhalte auf die sie in ihrem
                                Angebot verweist, nicht ständig auf Veränderung, die eine Rechtswidrigkeit
                                neu begründen könnten. Wenn die Unfallkasse NRW feststellt oder von anderen darauf
                                hingewiesen wird, dass ein konkretes Angebot zu dem sie einen Link
                                bereitgestellt hat, eine zivil- oder strafrechtliche Verantwortlichkeit
                                auslöst, wird sie den Verweis auf dieses Angebot aufheben.</p>
                        </div>
                    </div>
                </div>
            }>
        </DefaultLayout>
    )
}